import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import ThreeColumn from '../components/ThreeColumn'
import SubNavbar from '../components/SubNavbar'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const BlueprintPageTemplate = ({
  bgimagesecondary,
  heading,
  subheading,
  ctatitle,
  ctaurl,
  mainimage,
  allensinterests,
  organicgrowthupper,
  organicgrowthlower,
  trackperformance,
  testimonial,
  featuresLogo,
  featuresheader,
  features,
  helmet,
}) => (
  <div className="blueprint-overview-pg">
    {helmet || ''}
    <div className="full-width-image margin-top-0 hero-bg">
      <div className="container">

        <div className="hero-text section"
          style={{
            display: 'flex',
            alignItems: 'left',
            flexDirection: 'column',
            color: '#fff',
          }}>

          <div className="columns">
            <div className="column is-4 main-text">
              <div>
                <div className="logoWrapper">
                  <PreviewCompatibleImage imageInfo={featuresLogo} />
                </div>
                <h1
                  className="has-text-weight-bold"
                  style={{
                    lineHeight: '1.2',
                    marginBottom: '10px'
                  }}
                >{heading}</h1>
                <p>{subheading}</p>
                <div className="cta-btn cta-btn__secondary">
                  <a href={`${ctaurl}`} className="btn-underline" target="_blank" rel="noopener noreferrer">{ctatitle}</a>
                </div>
              </div>
            </div>
            <div className="column is-8">
              <div className="laptop-image">
                <PreviewCompatibleImage imageInfo={mainimage} style={{ maxHeight: '410px' }} />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="hero-side-bg" style={{
        backgroundImage: `url(${
          !!bgimagesecondary.childImageSharp ? bgimagesecondary.childImageSharp.fluid.src : bgimagesecondary
        })`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
        height: '596px',
        width: '473px',
        position: 'absolute',
        }}
      ></div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section" style={{padding: '0'}}>
          <div className="columns">
            <div className="column is-12">
              <div className="content hm-page">

                <div className="columns strategy-section">
                  <div className="column is-12">
                    <div className="column is-12 two-column">
                      <section className="section" style={{
                          paddingBottom: '6rem'
                        }}>
                        <div className="columns">
                          <div className="column is-4 text">
                            <h3>Search algorithms are getting smarter. </h3>
                          </div>
                          <div className="has-text-centered column is-4">
                            <div className="strategy-section-image">
                              <PreviewCompatibleImage imageInfo={allensinterests.image1} />
                            </div>
                          </div>
                          <div className="column is-4 text">
                            <h3>{allensinterests.text}</h3>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient organic-growth-wrapper green-bg">
      <div className="container">
        <div className="columns organic-growth-upper-section">
            <div className="column is-12 two-column">
              <div className="container">
                <section className="section">
                  <div className="columns">
                    <div className="has-text-centered column is-7">
                      <div className="two-column__image-wrapper">
                        <PreviewCompatibleImage imageInfo={organicgrowthupper.image1} style={{ width: `544px`, }} />
                      </div>
                    </div>
                    <div className="column is-4 two-column--text">
                      <div className="two-column--text-body-wrapper">
                        <h2>{organicgrowthupper.header}</h2>
                        <div className="two-column--text-body"
                          dangerouslySetInnerHTML={{
                            __html: organicgrowthupper.text,
                            }}
                          />
                        </div>
                      </div>
                  </div>
                </section>
              </div>
            </div>
        </div>
      </div>
    </section>

    <section className="section section--gradient organic-growth-wrapper">
      <div className="container">
        <div className="columns organic-growth-lower-section">
          <div className="column is-12 two-column">
            <div className="container">
              <section className="section">
                <div className="columns">
                  <div className="column is-4 two-column--text">
                    <div className="two-column--text-body-wrapper">
                      <h2>{organicgrowthlower.header}</h2>
                      <div className="two-column--text-body"
                        dangerouslySetInnerHTML={{ __html: organicgrowthlower.text }}
                      />
                    </div>
                  </div>
                  <div className="column is-8">
                    <div className="two-column__image-wrapper">
                      <PreviewCompatibleImage imageInfo={organicgrowthlower.image1} style={{ width: `544px`,}} />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient organic-growth-wrapper green-bg trackperformance-section">
      <div className="container">
          <div className="columns organic-growth-upper-section">
              <div className="column is-12 two-column">
                <div className="container">
                  <section className="section">
                    <div className="columns">
                      <div className="has-text-centered column is-7">
                        <div className="two-column__image-wrapper">
                          <PreviewCompatibleImage imageInfo={trackperformance.image1} style={{ width: `544px`,}}/>
                        </div>
                      </div>
                      <div className="column is-4 two-column--text">
                        <div className="two-column--text-body-wrapper">
                          <h2>{trackperformance.header}</h2>
                          <div className="two-column--text-body"
                            dangerouslySetInnerHTML={{ __html: trackperformance.text }}
                          />
                          </div>
                        </div>
                    </div>
                  </section>
                </div>
              </div>
          </div>
        </div>
    </section>
    <section className="section section--gradient" id="testimonial-section">
      <div className="container">
        <div className="section" style={{ padding: '0' }}>
          <div className="columns">
            <div className="column is-12">
                <div className="testimonial-section-textbox">
                  <p className="testimonial-section-quote">{testimonial.quote}</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient" id="features-section">
      <div className="container">
        <div className="section" style={{ padding: '0' }}>
          <div className="columns">
            <div className="column is-12">
                <div className="logoWrapper">
                  <PreviewCompatibleImage imageInfo={featuresLogo} />
                </div>
                <h2 style={{
                  maxWidth: '350px',
                  margin: '0 auto',
                  textAlign: 'center'
                }}>{featuresheader}</h2>
                <ThreeColumn gridItems={features} />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

BlueprintPageTemplate.propTypes = {
  software: PropTypes.string,
  title: PropTypes.string,
  metatitle: PropTypes.string,
  metadescription: PropTypes.string,
  bgimagesecondary: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  ctatitle: PropTypes.string,
  ctaurl: PropTypes.string,
  mainimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  allensinterests: PropTypes.shape({
    header: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
  }),
  organicgrowthupper: PropTypes.shape({
    header: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
  }),
  organicgrowthlower: PropTypes.shape({
    header: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
  }),
  trackperformance: PropTypes.shape({
    header: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
  }),
  testimonial: PropTypes.shape({
    quote: PropTypes.string,
  }),
  featureslogo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  featuresheader: PropTypes.string,
  features: PropTypes.array,
  helmet: PropTypes.object,
}

const BlueprintPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <div className="subnavtop">
      <SubNavbar software={frontmatter.software} />
      <Layout software={frontmatter.software} >
        <BlueprintPageTemplate
          {...frontmatter}
          helmet={
            <Helmet titleTemplate="%s | Human">
              <title>{`${frontmatter.metatitle}`}</title>
              <meta name="description" content={`${frontmatter.metadescription}`} />
            </Helmet>
          }
        />
      </Layout>
    </div>
  )
}

BlueprintPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default BlueprintPage

export const pageQuery = graphql`
  query BlueprintPageTemplate {
    markdownRemark( frontmatter: { templateKey: { eq: "blueprint-page" } }) {
      frontmatter {
        software
        title
        metatitle
        metadescription
        bgimagesecondary {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heading
        subheading
        ctatitle
        ctaurl
        mainimage {
          image {
            childImageSharp {
              fluid(maxWidth: 888, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
        }
        allensinterests {
          header
          image1{
            image {
              childImageSharp {
                fluid(maxWidth: 310, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
          text
        }
        organicgrowthupper {
          header
          image1 {
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
          text
        }
        organicgrowthlower {
          header
          image1 {
            image {
              childImageSharp {
                fluid(maxWidth: 888, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
          text
        }
        trackperformance {
          header
          image1 {
            image {
              childImageSharp {
                fluid(maxWidth: 908, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
          text
        }
        testimonial {
          quote
        }
        featuresLogo {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        featuresheader
        features {
          image {
            childImageSharp {
              fluid(maxWidth: 170, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text
          header
        }
      }
    }
  }
`
